<!--
	This is the Orders List page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>
    <div v-if="pageFlag === 'list'">
      <!-- Orders List header -->
      <!-- / Orders List header -->

      <!-- Orders List card -->
      <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">

        <!-- Table search -->
        <div class="mx-25">
          <a-row type="flex" :gutter="24">
            <a-col :span="12" class="text-left">
              <a-input-search placeholder="请输入查询信息" style="max-width: 200px;" v-model="query" @search="onSearch" size="small" />
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" size="small" @click="visible = true">{{ $t('create') }}</a-button>
            </a-col>
          </a-row>
        </div>
        <!-- / Table search -->

        <!-- Orders table -->
        <a-table class="mt-20"
                 :columns="columns"
                 :data-source="data"
                 rowKey="code"
                 :loading="loading"
                 :pagination="{pageSize: pageSize,current: pageNum,total:total}"
                 :scroll="{ x: true, y: true }"
                 @change="handleTableChange"
        >

          <template slot="typeName" slot-scope="row">
            <a href="javascript:void(0)" @click="goDetail(row)">{{ row.name }}</a>
          </template>
          <template slot="isCommon" slot-scope="tenantId">
            <div>
              <a-tag color="orange" v-if="tenantId !== null">
                {{ $t('custom') }}
              </a-tag>
              <a-tag color="red" v-else>
                {{ $t('system') }}
              </a-tag>
            </div>
          </template>

          <template slot="status" slot-scope="status">
            <span v-if="status === 1" style="vertical-align: middle;font-size: 12px;color: #87d068">{{ $t('enable') }}</span>
            <span v-else style="vertical-align: middle;font-size: 12px;color: #f50">{{ $t('invalid') }}</span>
          </template>

          <!--        <template slot="customer" slot-scope="customer">-->
          <!--          <div class="table-avatar-info">-->
          <!--            <a-avatar v-if="customer.avatar" shape="circle" :size="24" :src="customer.avatar" />-->
          <!--            <a-avatar v-else shape="circle" :size="24">{{ customer.name.slice(0, 1) }}</a-avatar>-->
          <!--            <div class="avatar-info">-->
          <!--              <p class="mb-0 text-dark">{{ customer.name }}</p>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </template>-->

          <template slot="operation" slot-scope="row">
            <a-icon type="edit" style="cursor: pointer;margin-right: 10px" @click="edit(row)"/>
            <a-popconfirm :title="$t('delConfirmMsg')" :ok-text="$t('yes')" :cancel-text="$t('no')" @confirm="del(row.id)">
              <a-icon type="delete" style="cursor: pointer"/>
            </a-popconfirm>
          </template>

        </a-table>
        <!-- / Orders table -->

      </a-card>
      <a-drawer
          :title="drawerTitle"
          placement="right"
          :closable="true"
          :visible="visible"
          width="40%"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
      >
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0 }" :loading="drawerLoading">
          <a-form-item class="mb-10" :label="$t('tenant.tenant')" :colon="false" >
            <a-select
                show-search
                allowClear
                placeholder="选择租户"
                option-filter-prop="children"
                style="width: 100%;margin-right: 8px"
                size="small"
                v-model="form.tenantId"
                :filter-option="filterOption"
            >
              <a-select-option v-for="tenant in tenantList" :key="tenant.id" :value="tenant.id" >
                {{ tenant.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('param.code')" :colon="false" required>
            <a-input v-model="form.code" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('param.name') + ' [中文]'" :colon="false" required>
            <a-input v-model="form.nameZh" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('param.name') + ' [EN]'" :colon="false" required>
            <a-input v-model="form.nameEn" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('param.status')" :colon="false" >
            <a-select
                show-search
                allowClear
                option-filter-prop="children"
                style="width: 100%;margin-right: 8px"
                size="small"
                v-model="form.status"
                :filter-option="filterOption"
            >
              <a-select-option :value="1" >
                {{ $t('enable') }}
              </a-select-option>
              <a-select-option :value="0" >
                {{ $t('invalid') }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item style="text-align: right">
            <a-input v-model="form.id" size="small" type="hidden"/>
            <a-button type="primary" html-type="submit" @click="submit"  size="small">
              {{ $t('submit') }}
            </a-button>
          </a-form-item>
        </a-card>
      </a-drawer>
      <!-- / Orders List card -->

    </div>
    <detail v-if="pageFlag === 'detail'" :id="detailId" :title="detailTitle" :tenant-list="tenantList"/>
  </div>

</template>

<script>
import Detail from "./Detail";

export default {
  components: { Detail },
  data() {
    return {
      postFlag: true,
      loading: false,
      uploadLoading: false,
      drawerLoading: false,
      visible: false,
      drawerTitle: this.$t('create'),
      pageFlag: 'list',
      imageUrl: '',
      // Table columns
      columns: [
        {
          title: this.$i18n.t('param.typeName'),
          scopedSlots: { customRender: 'typeName' },
        },
        {
          title: this.$i18n.t('param.typeCode'),
          dataIndex: 'code',
          // scopedSlots: { customRender: 'code' },
          width: 130
        },
        {
          title: this.$i18n.t('isCommon'),
          dataIndex: 'tenantId',
          scopedSlots: { customRender: 'isCommon' },
          width: 100
        },
        {
          title: this.$i18n.t('tenant.tenant'),
          dataIndex: 'tenantName',
          width: 150
        },
        {
          title: this.$i18n.t('tenant.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: this.$i18n.t('lastModifiedDate'),
          dataIndex: 'lastModifiedDate',
          width: 180
        },
        {
          title: this.$i18n.t('operation'),
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 120
        },
      ],

      // Table rows
      data: [],

      // Table page size
      pageSize: 10,
      pageNum: 1,
      total: 0,

      // Table search query
      query: '',

      // Table's selected rows
      selectedRowKeys: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: null,
        tenantId: undefined,
        code: null,
        nameZh: null,
        nameEn: null,
        lastModifiedDate: null,
        status: 1
      },
      tenantList: [],
      detailId: null,
      detailTitle: null
    }
  },
  computed: {

    // CSV data array
    csvData() {
      return this.data.map(item => ({
        "Id": "%23" + item.key,
        "Date": item.date,
        "Status": item.status,
        "Customer": item.customer.name,
        "Product": item.product,
        "Revenue": "$" + item.revenue,
      }));
    }

  },
  mounted() {
    this.getTenantList()
    this.getData()
  },
  methods: {
    getTenantList() {
      this.$store.dispatch('TENANT_LIST', {params: {pageNum: 1,pageSize: 9999}, data: {}}).then(res => {
        if (res.errorCode === '0') {
          this.tenantList = res.data.list
        }
      })
    },
    afterVisibleChange() {},
    onSubmit() {},
    onClose() {
      this.visible = false
      this.resetForm()
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // Event listener for input change on table search field.
    onSearch() {
      this.getData()
    },

    // Event listener for table row selection change.
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // Export table in CSV format.
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join("|"),
        ...arrData.map(item => Object.values(item).join("|"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "muse-dashboard-csv.csv");
      link.click();
    },
    getData() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const searchForm = {
        name: this.query
      }
      this.$store.dispatch('PARAM_TYPE_LIST', {params: params, data: searchForm}).then(res => {
        if (res.errorCode === '0') {
          this.data = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    submit() {
      this.drawerLoading = true
      this.form.logo = this.imageUrl
      if (this.postFlag) {
        this.postFlag = false
        this.$store.dispatch("SAVE_PARAM_TYPE", this.form).then(res => {
          this.drawerLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.visible = false
            this.resetForm()
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.drawerLoading = false
          this.postFlag = true
          this.$message.error(err)
        })

      }
    },
    resetForm() {
      this.form = {
        id: null,
        tenantId: null,
        code: null,
        nameZh: null,
        nameEn: null,
        domain: null,
        lastModifiedDate: null,
        status: 1
      }
      this.drawerTitle = this.$t('create')
    },
    edit(row) {
      this.drawerTitle = this.$t('edit')
      this.form = row
      this.visible = true
    },
    handleTableChange(pagination) {
      this.pageNum = pagination.current
      this.total = pagination.total
      this.getData()
    },
    del(id) {
      const param = {
        id: id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.loading = true
        this.$store.dispatch('DEL_PARAM_TYPE', param).then(res => {
          this.postFlag = true
          if (res.errorCode === '0') {
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }).catch(err => {
          this.postFlag = true
          this.loading = false
          this.$message.error(err)
        })
      }
    },
    goDetail(row) {
      this.pageFlag = 'detail'
      this.detailId = row.id
      this.detailTitle = row.name
    },
    goList() {
      this.pageFlag = 'list'
    }
  },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
.ant-select::v-deep .ant-select-selection__placeholder {
  font-weight: normal;
}
.ant-input-search::v-deep input::placeholder {
  font-weight: normal;
}
</style>